import React from 'react';
import Container from 'react-bootstrap/Container';

import Layout from '../components/Layout';
import VerifyLicence from '../components/VerifyLicence';

const verify = () => {
  return (
    <Layout>
      <Container>
        <VerifyLicence />
      </Container>
    </Layout>
  );
};

export default verify;
