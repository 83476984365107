import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Helmet from 'react-helmet';

const VerifyLicence = () => {
  const [number, setNumber] = useState('');
  const [queryData, setQueryData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const data = useStaticQuery(
    graphql`
      {
        info: allContentfulLicence {
          nodes {
            cnic
            licenseNumber
            driverName
            fatherhusbandName
            dateOfBirth
            allowedVehicles
            city
            state
            issueDate
            validFrom
            validTo
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  );

  const submitCnic = e => {
    e.preventDefault();
    setLoading(true);
    // find the index
    const index = data.info.nodes.findIndex(index => index.cnic === number);

    if (index === -1) {
      setError({ msg: `Licence with that "${number}" CNIC is not found` });
      setLoading(false);
      setQueryData({});
    } else {
      setError({});
      setLoading(false);
      setQueryData(data.info.nodes[index]);
    }
  };

  return (
    <div id="wrapper">
      <Helmet>
        <title>Verify Licence</title>
      </Helmet>
      <section className="py-5">
        <Card>
          <Card.Body>
            {error.msg ? (
              <Alert variant="danger" className="text-center">
                {error.msg}
              </Alert>
            ) : null}
            <form>
              <input
                type="number"
                value={number}
                className="form-control"
                placeholder="Please Enter Your CNIC without dashes. (11111111223331)"
                onChange={e => setNumber(e.target.value)}
              />
              <button
                onClick={submitCnic}
                className="btn btn-outline-success btn-lg mt-3"
              >
                Verify
              </button>
            </form>
            {loading ? null : (
              <div className="py-5">
                {queryData && (
                  <Row>
                    <Col md={6}>
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          marginBottom: '30px',
                        }}
                      >
                        {queryData.image ? (
                          <Image
                            fluid={queryData.image.fluid}
                            className="rounded-circle"
                          />
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      {queryData.cnic && (
                        <>
                          <h4 className="h4">Driver Information</h4>
                          <div className="licence-line"></div>
                          <h6>
                            CNIC:{' '}
                            <strong className="text-warning">
                              {queryData.cnic}
                            </strong>
                          </h6>
                          <h6>
                            license Number:{' '}
                            <strong className="text-warning">
                              {queryData.licenseNumber}
                            </strong>
                          </h6>
                          <h6>Driver Name: {queryData.driverName}</h6>
                          <h6>
                            Father/Husband Name: {queryData.fatherhusbandName}
                          </h6>
                          <h6>Date Of Birth: {queryData.dateOfBirth}</h6>
                          <h6>Allowed Vehicles: {queryData.allowedVehicles}</h6>
                          <h6>Sate: {queryData.state}</h6>
                          <h6>City: {queryData.city}</h6>
                          <h4 className="h4 mt-3">Licence Duration</h4>
                          <div className="licence-line"></div>
                          <h6>Issue Date: {queryData.issueDate}</h6>
                          <h6>Valid From: {queryData.validFrom}</h6>
                          <h6>Expire Date: {queryData.validTo}</h6>
                        </>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </section>
    </div>
  );
};

export default VerifyLicence;
